import React, {useEffect, useState} from 'react';
import {Divider, NavBar} from "antd-mobile";
import {useNavigate, useParams} from "react-router-dom";
import {FillinOutline} from "antd-mobile-icons";
import {getSecretInfo} from "../../services/secret";
import response from "../../utils/response";

import './details.css';

const SecretDetails = () => {
    const { id } = useParams();

    const [info, setInfo] = useState({});
    const navigate = useNavigate();

    function handleGoBack() {
        navigate(-1);
    }

    function getData() {
        getSecretInfo(id).then(res => {
            const {code, data} = res || {};

            if (response.isSuccess(code)) {
                setInfo(data);
            }
        });
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    const toEditPage = () => {
        navigate('/secret/edit/' + id);
    };

    const navBarRight = (
        <div style={{ fontSize: 24 }}>
            <FillinOutline onClick={toEditPage} />
        </div>
    );

    return (
        <div className="page-container">
            <div className="page-top">
                <NavBar
                    onBack={handleGoBack}
                    right={navBarRight}
                >
                    详情
                </NavBar>
            </div>
            <div className="page-body">
                <div className="secret-details-title">
                    <h3>{info?.title}</h3>
                    <p>{info?.create_time}</p>
                </div>
                <Divider />
                <div className="secret-details-content">{info?.content}</div>
            </div>
        </div>
    )
};

export default SecretDetails;