import React, {useEffect} from 'react';
import {Button, Form, Input, NavBar} from "antd-mobile";
import {useNavigate, useParams} from "react-router-dom";
import {editCipher, getCipherInfo} from "../../services/cipher";
import response from "../../utils/response";
import aes from "../../utils/aes";

import './edit.css';

const CipherEdit = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    function handleGoBack() {
        navigate(-1);
    }

    function getData() {
        getCipherInfo(id).then(res => {
            const {code, data} = res || {};

            if (response.isSuccess(code)) {
                if (data.account !== '') {
                    data.account = aes.decrypt(data.account);
                }
                if (data.password !== '') {
                    data.password = aes.decrypt(data.password);
                }

                form.setFieldsValue(data);
            }
        });
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    const onFinish = (values) => {
        if (values.account !== '') {
            values.account = aes.encrypt(values.account);
        }
        if (values.password !== '') {
            values.password = aes.encrypt(values.password);
        }

        editCipher(id, values).then(res => {
            let {code} = res || {};

            if (response.isSuccess(code)) {
                handleGoBack();
            }
        });
    };

    return (
        <div className="page-container">
            <div className="page-top">
                <NavBar
                    onBack={handleGoBack}
                >
                    编辑
                </NavBar>
            </div>
            <div className="page-body">
                <Form
                    form={form}
                    mode='card'
                    layout='horizontal'
                    style={{'--prefix-width': '2.5em'}}
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <Form.Item name='name' label='名称' rules={[{ required: true }]}>
                        <Input placeholder='请输入名称' clearable={true} />
                    </Form.Item>
                    <Form.Item name='account' label='账号'>
                        <Input placeholder='请输入账号' clearable={true} />
                    </Form.Item>
                    <Form.Item name='password' label='密码' rules={[{ required: true }]}>
                        <Input placeholder='请输入密码' clearable={true} />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
};

export default CipherEdit;