import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PullToRefresh, List, NavBar, Empty, SwipeAction, Dialog} from 'antd-mobile';
import {AddCircleOutline} from "antd-mobile-icons";
import {deleteCipher, getCipherList} from "../../services/cipher";
import toast from "../../utils/toast";
import response from "../../utils/response";
import aes from "../../utils/aes";

import './list.css';

const CipherList = () => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);

    function getData() {
        getCipherList().then(res => {
            let {code, data} = res || {};

            if (response.isSuccess(code)) {
                setList(data);
            }
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const toAddPage = () => {
        navigate('/cipher/add');
    };

    const navBarRight = (
        <div style={{ fontSize: 24 }}>
            <AddCircleOutline onClick={toAddPage} />
        </div>
    );

    function handleClick(id) {
        navigate('/cipher/edit/' + id);
    }

    function handleDelete(id) {
        Dialog.confirm({
            content: '确定要删除吗？',
            onConfirm: async () => {
                deleteCipher(id).then(res => {
                    const {code} = res || {};

                    if (response.isSuccess(code)) {
                        toast.success('删除成功');

                        const newList = list.filter(item => item.id !== id);
                        setList(newList);
                    }
                });
            },
        });
    }

    function handleShow(data) {
        Dialog.alert({
            content: <div style={{fontSize: '18px', lineHeight: '1.5'}}>
                <div>账号：{aes.decrypt(data.account)}</div>
                <div>密码：{aes.decrypt(data.password)}</div>
            </div>,
        })
    }

    return (
        <div className="page-container">
            <div className="page-top" onClick={()=>{}}>
                <NavBar
                    backArrow={false}
                    right={navBarRight}
                >
                    密钥
                </NavBar>
            </div>
            <div className="page-body page-bg">
                <PullToRefresh
                    onRefresh={() => getData()}
                >
                    <List>
                        {list.map(item => (
                            <SwipeAction
                                key={item.id}
                                rightActions={[
                                    {
                                        key: 'edit',
                                        text: '编辑',
                                        color: 'warning',
                                        onClick: () => handleClick(item.id),
                                    },
                                    {
                                        key: 'delete',
                                        text: '删除',
                                        color: 'danger',
                                        onClick: () => handleDelete(item.id),
                                    },
                                ]}
                            >
                                <List.Item
                                    key={item.id}
                                    // extra={item.password}
                                    // description={item.account}
                                    onClick={() => handleShow(item)}
                                >
                                    {item.name}
                                </List.Item>
                            </SwipeAction>
                        ))}
                        {list.length === 0 ? (
                            <Empty
                                style={{ padding: '64px 0' }}
                                description='暂无数据'
                            />
                        ) : ''}
                    </List>
                </PullToRefresh>
            </div>
        </div>
    )
};

export default CipherList;