import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Avatar, Dialog, List, NumberKeyboard, PasscodeInput, PullToRefresh} from "antd-mobile";
import {PayCircleOutline, ReceiptOutline, HistogramOutline, KeyOutline, LockOutline} from 'antd-mobile-icons';
import toast from "../../utils/toast";
import {doLogout, getUserInfo} from "../../services/user";
import {clearAuth} from "../../utils/auth";
import {setPasscode} from "../../utils/lockpage";
import response from "../../utils/response";

import './index.css';

const PersonalCenter = () => {
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({});
    const [netAssets, setNetAssets] = useState('0.00');
    const [propertyAmount, setPropertyAmount] = useState('0.00');
    const [liabilitiesAmount, setLiabilitiesAmount] = useState('0.00');

    let lockPassword = '';

    function getData() {
        getUserInfo().then(res => {
            let {code, data} = res || {};

            if (response.isSuccess(code)) {
                setUserInfo(data?.user_info);
                setNetAssets(data?.net_assets);
                setPropertyAmount(data?.property_amount);
                setLiabilitiesAmount(data?.liabilities_amount);
            }
        });
    }

    useEffect(() => {
        getData();
    }, []);
    
    
    function toPropertyHistoryPage() {
        navigate('/property/historyList');
    }

    function toChangePwdPage() {
        navigate('/changePwd');
    }

    const onPasscodeChange = (value: string) => {
        lockPassword = value;
    };
    
    function handleLockPage() {
        Dialog.confirm({
            title: '设置密码',
            content: <div style={{marginTop: '8px', textAlign: 'center'}}>
                <PasscodeInput onChange={onPasscodeChange} keyboard={<NumberKeyboard />} />
            </div>,
            onConfirm: () => {
                if (lockPassword.length === 6) {
                    setPasscode(lockPassword);
                    navigate('/unlockPage');
                } else {
                    toast.show('请设置6位密码');
                    throw new Error();
                }
            },
        });
    }

    function handleLogout() {
        Dialog.confirm({
            content: '确定退出登录吗？',
            onConfirm: async () => {
                doLogout().then(res => {
                    toast.success('退出成功');

                    setTimeout(() => clearAuth(true), 1000);
                });
            },
        });
    }

    return (
        <div className="page-container">
            <div className="page-body page-bg">
                <PullToRefresh
                    onRefresh={() => getData()}
                >
                    <div className="me-user-info">
                        <List style={{'--border-top': '0px', '--border-bottom': '0px', '--padding-left': '24px'}}>
                            <List.Item
                                prefix={<Avatar src={userInfo?.avatar} style={{ '--size': '64px' }} />}
                                description={<div className="me-user-info-username">账号：{userInfo?.username || ''}</div>}
                            >
                                <div className="me-user-info-nickname">
                                    {userInfo?.nickname}
                                </div>
                            </List.Item>
                        </List>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <List>
                            <List.Item prefix={<PayCircleOutline />} extra={netAssets + ' 元'} arrow={false} clickable={false} onClick={() => {}}>
                                净资产
                            </List.Item>
                            <List.Item prefix={<PayCircleOutline />} extra={propertyAmount + ' 元'} arrow={false} clickable={false} onClick={() => {}}>
                                总资产
                            </List.Item>
                            <List.Item prefix={<ReceiptOutline />} extra={liabilitiesAmount + ' 元'} arrow={false} clickable={false} onClick={() => {}}>
                                总负债
                            </List.Item>
                            <List.Item prefix={<HistogramOutline />} onClick={toPropertyHistoryPage}>
                                资产趋势
                            </List.Item>
                            <List.Item prefix={<KeyOutline />} onClick={toChangePwdPage}>
                                修改密码
                            </List.Item>
                            <List.Item prefix={<LockOutline />} onClick={handleLockPage}>
                                锁定页面
                            </List.Item>
                        </List>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <List>
                            <List.Item arrow={false} onClick={handleLogout}>
                                <div className="adm-auto-center">退出登录</div>
                            </List.Item>
                        </List>
                    </div>
                </PullToRefresh>
            </div>
        </div>
    );
};

export default PersonalCenter;