import {delCookie, getCookie, setCookie} from "./cookie";
import aes from "./aes";

const passwordKey = 'lock_password';

function encryptPasscode(passcode) {
    return aes.encrypt(passcode);
}

function decryptPasscode(passcode) {
    return aes.decrypt(passcode);
}

export function checkPasscode(passcode) {
    return decryptPasscode(getPasscode()) === passcode;
}

export function getPasscode() {
    return getCookie(passwordKey);
}

export function setPasscode(passcode) {
    setCookie(passwordKey, encryptPasscode(passcode), 30);
}

export function delPasscode() {
    delCookie(passwordKey);
}
