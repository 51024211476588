import {getSecretKey} from "./secretkey";
import CryptoJS from 'crypto-js';

const options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
};

export function encrypt(data) {
    const key = getSecretKey();

    return CryptoJS.AES.encrypt(data, key, options).toString();
}

export function decrypt(cipherText) {
    const key = getSecretKey();

    return CryptoJS.AES.decrypt(cipherText, key, options).toString(CryptoJS.enc.Utf8);
}

const aes = {
    encrypt,
    decrypt
};

export default aes;