import React from "react";
import {Dialog, NumberKeyboard, PasscodeInput} from "antd-mobile";
import {checkPasscode, delPasscode} from "../../utils/lockpage";
import toast from "../../utils/toast";

import './unlockpage.css';
import {useNavigate} from "react-router-dom";
import {doLogout} from "../../services/user";
import {clearAuth} from "../../utils/auth";

const UnlockPage = () => {
    const navigate = useNavigate();

    const onPasscodeChange = (value: string) => {
        if (value.length === 6) {
            if (checkPasscode(value)) {
                delPasscode();
                // 回到首页
                navigate('/', {replace: true});
            } else {
                toast.show('密码错误');
            }
        }
    };

    function handleLogout() {
        Dialog.confirm({
            content: '确定退出登录吗？',
            onConfirm: async () => {
                doLogout().then(res => {
                    toast.success('退出成功');

                    setTimeout(() => clearAuth(true), 1000);
                });
            },
        });
    }

    return (
        <div className="page-container unlock-container">
            <div className="page-body">
                <h2>输入解锁密码</h2>
                <PasscodeInput onChange={onPasscodeChange} keyboard={<NumberKeyboard />} style={{'--border-color': '#999999'}} />
                <div className="unlock-btn-body">
                    <span className="unlock-btn-logout" onClick={handleLogout}>
                        退出登录
                    </span>
                </div>
            </div>
        </div>
    );
};

export default UnlockPage;
