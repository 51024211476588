import React from 'react';
import {Button, Form, Input, NavBar} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import {addCipher} from "../../services/cipher";
import response from "../../utils/response";
import aes from "../../utils/aes";

import './add.css';

const CipherAdd = () => {
    const navigate = useNavigate();

    function handleGoBack() {
        navigate(-1);
    }

    const onFinish = (values) => {
        if (values.account !== '') {
            values.account = aes.encrypt(values.account);
        }
        if (values.password !== '') {
            values.password = aes.encrypt(values.password);
        }

        addCipher(values).then(res => {
            let {code} = res || {};

            if (response.isSuccess(code)) {
                handleGoBack();
            }
        });
    };

    return (
        <div className="page-container">
            <div className="page-top">
                <NavBar
                    onBack={handleGoBack}
                >
                    添加
                </NavBar>
            </div>
            <div className="page-body">
                <Form
                    mode='card'
                    layout='horizontal'
                    style={{'--prefix-width': '2.5em'}}
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <Form.Item name='name' label='名称' rules={[{ required: true }]}>
                        <Input placeholder='请输入名称' clearable={true} />
                    </Form.Item>
                    <Form.Item name='account' label='账号'>
                        <Input placeholder='请输入账号' clearable={true} />
                    </Form.Item>
                    <Form.Item name='password' label='密码' rules={[{ required: true }]}>
                        <Input placeholder='请输入密码' clearable={true} />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
};

export default CipherAdd;