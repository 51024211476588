import request from '../utils/request';

export function doLogin(data) {
    return request.post(`/user/login`, data);
}

export function doLogout() {
    return request.post(`/user/logout`, {});
}

export function getUserInfo() {
    return request.get(`/user/index`);
}

export function changePwd(data) {
    return request.post(`/user/changePwd`, data);
}