import {Toast} from "antd-mobile";

function show(content, props = {}) {
    let p = Object.assign({
        content: content,
    }, props);

    return Toast.show(p);
}

function success(content, props = {}) {
    props.icon = 'success';
    return show(content, props);
}

function fail(content, props = {}) {
    props.icon = 'fail';
    return show(content, props);
}

function loading(content, props = {}) {
    props.icon = 'loading';
    return show(content, props);
}

const toast = {
    show,
    success,
    fail,
    loading
};

export default toast;