import {delCookie, getCookie, setCookie} from "./cookie";
import {delSecretKey} from "./secretkey";
import {delPasscode} from "./lockpage";

const tokenKey = 'user_token';

export function getToken() {
    return getCookie(tokenKey);
}

export function setToken(token) {
    setCookie(tokenKey, token, 30);
}

export function delToken() {
    delCookie(tokenKey);
}

export function clearAuth(toLoginPage) {
    delToken();
    delSecretKey();
    delPasscode();

    if (toLoginPage) {
        // 跳转登录页面
        window.location.replace('/login');
    }
}
