import React from 'react';
import {Button, Form, Input, NavBar, Selector} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import {addProperty} from "../../services/property";
import response from "../../utils/response";

import './add.css';

const PropertyAdd = () => {
    const navigate = useNavigate();
    const options = [
        {
            label: '资产',
            value: 1,
        },
        {
            label: '负债',
            value: 2,
        },
    ];

    function handleGoBack() {
        navigate(-1);
    }

    const onFinish = (values) => {
        values.type = values.type[0];

        addProperty(values).then(res => {
            let {code} = res || {};

            if (response.isSuccess(code)) {
                handleGoBack();
            }
        });
    };

    return (
        <div className="page-container">
            <div className="page-top">
                <NavBar
                    onBack={handleGoBack}
                >
                    添加
                </NavBar>
            </div>
            <div className="page-body">
                <Form
                    initialValues={{
                        type: [1],
                        amount: '0.00'
                    }}
                    mode='card'
                    layout='horizontal'
                    style={{'--prefix-width': '2.5em'}}
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <Form.Item name='type' label='类型' rules={[{ required: true }]}>
                        <Selector options={options} style={{'--padding': '3px 16px'}} />
                    </Form.Item>
                    <Form.Item name='name' label='名称' rules={[{ required: true }]}>
                        <Input placeholder='请输入名称' clearable={true} />
                    </Form.Item>
                    <Form.Item name='amount' label='数额' rules={[{ required: true }]}>
                        <Input type="number" placeholder='请输入数额' clearable={true} />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
};

export default PropertyAdd;