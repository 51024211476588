import React from "react";
import {useNavigate} from "react-router-dom";
import {Avatar, Button, Form, Input} from "antd-mobile";
import {doLogin} from "../../services/user";
import {setToken} from "../../utils/auth";
import {setSecretKey} from "../../utils/secretkey";
import {delPasscode} from "../../utils/lockpage";
import response from "../../utils/response";

import './login.css';

const Login = () => {
    const navigate = useNavigate();

    function toHomePage() {
        navigate('/', {replace: true});
    }

    const onFinish = (values) => {
        doLogin(values).then(res => {
            let {code, data} = res || {};

            if (response.isSuccess(code)) {
                const {secret_key, token_info} = data || {};
                if (token_info && token_info.token) {
                    setSecretKey(secret_key);
                    setToken(token_info.token);
                    delPasscode();

                    toHomePage();
                }
            }
        });
    };

    return (
        <div className="page-container login-container">
            <div className="page-body">
                <div className="adm-auto-center login-avatar">
                    <Avatar src='' style={{ '--size': '80px', 'borderRadius': '50%' }} />
                </div>
                <Form
                    name='form'
                    mode='card'
                    layout='horizontal'
                    style={{'--prefix-width': '2.5em'}}
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            登录
                        </Button>
                    }
                >
                    <Form.Item name='account' label='账号' rules={[{ required: true }]}>
                        <Input placeholder='请输入账号' clearable={true} />
                    </Form.Item>
                    <Form.Item name='password' label='密码' rules={[{ required: true }]}>
                        <Input type="password" placeholder='请输入密码' clearable={true} />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;