import axios from 'axios';
import QS from 'qs';
import {clearAuth, getToken} from "./auth";
import { baseUrl } from "../config/config";
import toast from "./toast";
import response from "./response";

const timeout = 20000;
var toastHandler = null;
var requestCount = 0;

const beforeRequest = () => {
    requestCount++;

    if (toastHandler === null) {
        toastHandler = toast.loading('', {
            duration: 0,
            maskClickable: false,
        });
    }
};

const afterRequest = () => {
    requestCount--;

    if (requestCount <= 0) {
        requestCount = 0;

        if (toastHandler !== null) {
            toastHandler.close();
            toastHandler = null;
        }
    }
};

// 创建axios实例
const instance = axios.create({
    baseURL: baseUrl,
    timeout: timeout,
    withCredentials: true,
});
// 设置post请求方法默认头部信息
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
instance.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

// 设置请求拦截器
instance.interceptors.request.use(config => {
    const combineConfig = config;

    if (combineConfig.method === 'post' || combineConfig.method === 'put') {
        if (config['Content-Type']) {
            combineConfig.headers['Content-Type'] = config['Content-Type'];
        } else if (combineConfig.headers['Content-Type'] !== 'multipart/form-data') {
            combineConfig.data = QS.stringify(combineConfig.data);
        }
    }

    combineConfig.headers.Authorization = getToken();

    beforeRequest();

    return combineConfig;
});

// 响应成功处理函数
const responseHandler = (res = {}) => {
    afterRequest();

    const { code, msg } = res.data;

    if (response.isUnauthorized(code)) {
        toast.show('请先登录再进行操作');
        setTimeout(() => clearAuth(true), 1000);

        return ;
    }
    else if (response.isFail(code)) {
        toast.show(msg);
    }

    return Promise.resolve(res.data)
};

// 响应失败处理函数
const errorHandler = (res = {}) => {
    const { statusText, data = {} } = res;

    const errorText = data.message || statusText || '请求失败，请稍后重试';

    // const errorResponse = {
    //     data,
    //     code: status,
    //     msg: errorText,
    // };

    afterRequest();

    toast.show(errorText);

    // return Promise.reject(errorResponse);
};

// 设置响应拦截器
instance.interceptors.response.use(
    res => responseHandler(res),
    err => errorHandler(err.response),
);

export default instance;
