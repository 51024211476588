import React, {useEffect} from 'react';
import {Button, Form, Input, NavBar, TextArea} from "antd-mobile";
import {useNavigate, useParams} from "react-router-dom";
import {editSecret, getSecretInfo} from "../../services/secret";
import response from "../../utils/response";

import './edit.css';

const SecretEdit = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [form] = Form.useForm();

    function handleGoBack() {
        navigate(-1);
    }

    function getData() {
        getSecretInfo(id).then(res => {
            const {code, data} = res || {};

            if (response.isSuccess(code)) {
                form.setFieldsValue(data);
            }
        });
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    const onFinish = (values) => {
        editSecret(id, values).then(res => {
            let {code} = res || {};

            if (response.isSuccess(code)) {
                handleGoBack();
            }
        });
    };

    return (
        <div className="page-container">
            <div className="page-top">
                <NavBar
                    onBack={handleGoBack}
                >
                    编辑
                </NavBar>
            </div>
            <div className="page-body">
                <Form
                    form={form}
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <Form.Item name='title' label='标题' rules={[{ required: true }]}>
                        <Input placeholder='请输入标题' clearable={true} />
                    </Form.Item>
                    <Form.Item name='content' label='内容' rules={[{ required: true }]}>
                        <TextArea
                            placeholder='请输入内容'
                            showCount
                            autoSize={{ minRows: 3, maxRows: 10 }}
                        />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
};

export default SecretEdit;