import request from '../utils/request';

export function getPropertyList() {
    return request.post(`/property/index`, {});
}

export function getPropertyInfo(id) {
    return request.post(`/property/show/` + id, {});
}

export function addProperty(data) {
    return request.post(`/property/add`, data);
}

export function editProperty(id, data) {
    return request.post(`/property/edit/` + id, data);
}

export function deleteProperty(id) {
    return request.post(`/property/delete/` + id, {});
}

export function getPropertyLogList(property_id, data) {
    return request.post(`/property/logList/` + property_id, data);
}

export function deletePropertyLog(id) {
    return request.post(`/property/deleteLog/` + id, {});
}

export function getPropertyHistoryList(data) {
    return request.post(`/property/historyList`, data);
}

export function getPropertyHistoryDetails(history_id) {
    return request.post(`/property/historyDetails/` + history_id);
}

export function createPropertyHistory() {
    return request.post(`/property/createHistory`);
}

export function deletePropertyHistory(history_id) {
    return request.post(`/property/deleteHistory/` + history_id);
}