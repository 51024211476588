import React from "react";

import Login from "../pages/login/login";
import Home from "../pages/home/home";
import SecretList from "../pages/secret/list";
import SecretAdd from "../pages/secret/add";
import SecretEdit from "../pages/secret/edit";
import SecretDetails from "../pages/secret/details";
import PropertyList from "../pages/property/list";
import PropertyAdd from "../pages/property/add";
import PropertyEdit from "../pages/property/edit";
import PropertyLogList from "../pages/property/loglist";
import PropertyHistoryList from "../pages/property/historylist";
import PropertyHistoryDetails from "../pages/property/historydetails";
import CipherList from "../pages/cipher/list";
import CipherAdd from "../pages/cipher/add";
import CipherEdit from "../pages/cipher/edit";
import PersonalCenter from "../pages/personal-center";
import ChangePwd from "../pages/personal-center/changepwd";
import UnlockPage from "../pages/personal-center/unlockpage";

const routes = [
    {
        // 首页
        path: "/",
        element: <Home />,
        children: [
            {
                // 秘密
                path: "/secret",
                element: <SecretList />,
            },
            {
                // 资产
                path: "/property",
                element: <PropertyList />,
            },
            {
                // 密钥
                path: "/cipher",
                element: <CipherList />,
            },
            {
                // 我的
                path: "/me",
                element: <PersonalCenter />,
            },
        ],
    },
    {
        // 登录
        path: "/login",
        element: <Login />,
    },
    {
        // 修改密码
        path: "/changePwd",
        element: <ChangePwd />,
    },
    {
        // 解锁页面
        path: "/unlockPage",
        element: <UnlockPage />,
    },
    {
        // 添加秘密
        path: "/secret/add",
        element: <SecretAdd />,
    },
    {
        // 编辑秘密
        path: "/secret/edit/:id",
        element: <SecretEdit />,
    },
    {
        // 秘密详情
        path: "/secret/details/:id",
        element: <SecretDetails />,
    },
    {
        // 添加资产
        path: "/property/add",
        element: <PropertyAdd />,
    },
    {
        // 编辑资产
        path: "/property/edit/:id",
        element: <PropertyEdit />,
    },
    {
        // 资产日志列表
        path: "/property/logList/:property_id",
        element: <PropertyLogList />,
    },
    {
        // 资产历史列表
        path: "/property/historyList",
        element: <PropertyHistoryList />,
    },
    {
        // 资产历史详情
        path: "/property/historyDetails/:history_id",
        element: <PropertyHistoryDetails />,
    },
    {
        // 添加密钥
        path: "/cipher/add",
        element: <CipherAdd />,
    },
    {
        // 编辑密钥
        path: "/cipher/edit/:id",
        element: <CipherEdit />,
    },
];

export default routes;