import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {PullToRefresh, List, NavBar, Empty} from 'antd-mobile';
import {getPropertyHistoryDetails} from "../../services/property";
import response from "../../utils/response";

import './historydetails.css';

const PropertyHistoryDetails = () => {
    const { history_id } = useParams();
    const navigate = useNavigate();

    const [dataDate, setDataDate] = useState('');
    const [netAssets, setNetAssets] = useState('0.00');
    const [propertyList, setPropertyList] = useState([]);
    const [propertyTotalAmount, setPropertyTotalAmount] = useState('0.00');
    const [liabilitiesList, setLiabilitiesList] = useState([]);
    const [liabilitiesTotalAmount, setLiabilitiesTotalAmount] = useState('0.00');

    function getData() {
        getPropertyHistoryDetails(history_id).then(res => {
            let {code, data} = res || {};

            if (response.isSuccess(code)) {
                setDataDate(data?.data_date);
                setNetAssets(data?.net_assets);
                setPropertyList(data?.property_list);
                setPropertyTotalAmount(data?.property_amount);
                setLiabilitiesList(data?.liabilities_list);
                setLiabilitiesTotalAmount(data?.liabilities_amount);
            }
        });
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    function handleGoBack() {
        navigate(-1);
    }

    return (
        <div className="page-container">
            <div className="page-top">
                <NavBar
                    onBack={handleGoBack}
                >
                    {dataDate}
                </NavBar>
            </div>
            <div className="page-body page-bg">
                <PullToRefresh
                    onRefresh={() => getData()}
                >
                    <div className="property-historydetails-net-assets">
                        <p className="property-historydetails-net-assets-amount">{netAssets}</p>
                        <p className="property-historydetails-net-assets-name">净资产(元)</p>
                    </div>
                    <List header={<div className="property-historydetails-list-amount">资产<span>{propertyTotalAmount} 元</span></div>}>
                        {propertyList.map(item => (
                            <List.Item
                                key={item.id}
                                extra={item.amount + ' 元'}
                            >
                                {item.name}
                            </List.Item>
                        ))}
                        {propertyList.length === 0 ? (
                            <Empty
                                style={{ padding: '64px 0' }}
                                description='暂无数据'
                            />
                        ) : ''}
                    </List>
                    <List header={<div className="property-historydetails-list-amount">负债<span>{liabilitiesTotalAmount} 元</span></div>}>
                        {liabilitiesList.map(item => (
                            <List.Item
                                key={item.id}
                                extra={item.amount + ' 元'}
                            >
                                {item.name}
                            </List.Item>
                        ))}
                        {liabilitiesList.length === 0 ? (
                            <Empty
                                style={{ padding: '64px 0' }}
                                description='暂无数据'
                            />
                        ) : ''}
                    </List>
                </PullToRefresh>
            </div>
        </div>
    )
};

export default PropertyHistoryDetails;