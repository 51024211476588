import request from '../utils/request';

export function getSecretList(data) {
    return request.post(`/secret/index`, data);
}

export function getSecretInfo(id) {
    return request.post(`/secret/show/` + id);
}

export function addSecret(data) {
    return request.post(`/secret/add`, data);
}

export function editSecret(id, data) {
    return request.post(`/secret/edit/` + id, data);
}

export function deleteSecret(id) {
    return request.post(`/secret/delete/` + id);
}