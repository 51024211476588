import React, {useEffect} from 'react';
import { TabBar } from 'antd-mobile';
import { useNavigate, useLocation, Outlet, } from 'react-router-dom';
import { ContentOutline, UnorderedListOutline, KeyOutline, UserOutline, } from 'antd-mobile-icons';

import './home.css';

const tabs = [
    {
        key: '/secret',
        title: '秘密',
        icon: <ContentOutline />,
    },
    {
        key: '/property',
        title: '资产',
        icon: <UnorderedListOutline />,
    },
    {
        key: '/cipher',
        title: '密钥',
        icon: <KeyOutline />,
    },
    {
        key: '/me',
        title: '我的',
        icon: <UserOutline />,
    },
];

const Bottom = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const setRouteActive = (value) => {
        navigate(value, {replace: true});
    };

    useEffect(() => {
        if (pathname === "/") {
            setRouteActive(tabs[0].key);
        }
    });

    return (<TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
        {tabs.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title}/>))}
    </TabBar>);
};

const Home = () => {
    return (
        <div className="app-container">
            <div className="app-body">
                <Outlet />
            </div>
            <div className="app-bottom">
                <Bottom />
            </div>
        </div>
    );
};

export default Home;