import React from 'react';
import {Button, Form, Input, NavBar} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import {changePwd} from "../../services/user";
import {clearAuth} from "../../utils/auth";
import toast from "../../utils/toast";
import response from "../../utils/response";

import './changepwd.css';

const ChangePwd = () => {
    const navigate = useNavigate();

    function handleGoBack() {
        navigate(-1);
    }

    const onFinish = (values) => {
        if (values.new_password !== values.confirm_password) {
            toast.show('新密码不一致');
            return false;
        }

        changePwd(values).then(res => {
            let {code} = res || {};

            if (response.isSuccess(code)) {
                toast.success('修改密码成功');

                setTimeout(() => clearAuth(true), 1000);
            }
        });
    };

    return (
        <div className="page-container">
            <div className="page-top">
                <NavBar
                    onBack={handleGoBack}
                >
                    修改密码
                </NavBar>
            </div>
            <div className="page-body">
                <Form
                    mode='card'
                    layout='horizontal'
                    style={{'--prefix-width': '4.5em'}}
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <Form.Item name='password' label='原密码' rules={[{ required: true }]}>
                        <Input type="password" placeholder='请输入原密码' clearable={true} />
                    </Form.Item>
                    <Form.Item name='new_password' label='新密码' rules={[{ required: true }]}>
                        <Input type="password" placeholder='请输入新密码' clearable={true} />
                    </Form.Item>
                    <Form.Item name='confirm_password' label='确认密码' rules={[{ required: true }]}>
                        <Input type="password" placeholder='再次输入新密码' clearable={true} />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
};

export default ChangePwd;