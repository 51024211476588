import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {getToken} from "../../utils/auth";
import {getPasscode} from "../../utils/lockpage";
import toast from "../../utils/toast";

type RouteProps = {
    children?: React.ReactNode
}

const indexRoute = '/';
const loginRoute = '/login';
const unlockRoute = '/unlockPage';

// 路由白名单
const allowRouteList = ['/login'];

const AuthRoute: React.FC<RouteProps> = (props) => {
    const location = useLocation();
    const { pathname } = location;

    // children 为子组件
    const { children } = props;

    let token = getToken();
    if (token && token !== 'undefined') {
        let passcode = getPasscode();

        // 有token
        // 禁止跳到登录页面，重定向到首页
        // 没有设置锁定密码禁止跳转解锁页面，重定向到首页
        if (pathname === loginRoute || (pathname === unlockRoute && (passcode === '' || passcode === 'undefined'))) {
            return <Navigate to={indexRoute}></Navigate>
        } else {
            // 有锁定密码且不是跳转解锁页面，重定向到解锁页面
            if (passcode && passcode !== 'undefined' && pathname !== unlockRoute) {
                return <Navigate to={unlockRoute}></Navigate>
            } else {
                // 正常跳转路由
                return <>{children}</>;
            }
        }
    } else {
        if (allowRouteList.includes(pathname || '')) {
            // 白名单，正常跳转
            return <>{children}</>;
        } else {
            toast.show('请先登录再进行操作');
            // 跳转登录页面
            return <Navigate to={loginRoute}></Navigate>;
        }
    }
};

export default AuthRoute;
