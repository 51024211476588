import request from '../utils/request';

export function getCipherList() {
    return request.post(`/cipher/index`);
}

export function getCipherInfo(id) {
    return request.post(`/cipher/show/` + id);
}

export function addCipher(data) {
    return request.post(`/cipher/add`, data);
}

export function editCipher(id, data) {
    return request.post(`/cipher/edit/` + id, data);
}

export function deleteCipher(id) {
    return request.post(`/cipher/delete/` + id);
}