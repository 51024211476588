import {successCode, unauthorizedCode} from "../config/constant";

function isSuccess(code) {
    return code === successCode;
}

function isFail(code) {
    return code !== successCode;
}

function isUnauthorized(code) {
    return code === unauthorizedCode;
}

const response = {
    isSuccess,
    isFail,
    isUnauthorized
};

export default response;
