import {delCookie, getCookie, setCookie} from "./cookie";

const secretKeyField = 'user_secret_key';

export function getSecretKey() {
    return getCookie(secretKeyField);
}

export function setSecretKey(secretKey) {
    setCookie(secretKeyField, secretKey, 30);
}

export function delSecretKey() {
    delCookie(secretKeyField);
}