import React, {useEffect, useState} from 'react';
import {Button, Form, Input, NavBar, Selector} from "antd-mobile";
import {useNavigate, useParams} from "react-router-dom";
import {editProperty, getPropertyInfo} from "../../services/property";
import response from "../../utils/response";

import './edit.css';

const PropertyEdit = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(0);
    const options = [
        {
            label: '资产',
            value: 1,
        },
        {
            label: '负债',
            value: 2,
        },
    ];

    function getData() {
        getPropertyInfo(id).then(res => {
            const {code, data} = res || {};

            if (response.isSuccess(code)) {
                setAmount(data.amount);

                data.type = [data.type];
                form.setFieldsValue(data);
            }
        });
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    function handleGoBack() {
        navigate(-1);
    }

    const onFinish = (values) => {
        values.type = values.type[0];

        if (values.change_amount) {
            values.amount = Number(amount) + Number(values.change_amount);
        }

        editProperty(id, values).then(res => {
            let {code} = res || {};

            if (response.isSuccess(code)) {
                handleGoBack();
            }
        });
    };

    return (
        <div className="page-container">
            <div className="page-top">
                <NavBar
                    onBack={handleGoBack}
                >
                    编辑
                </NavBar>
            </div>
            <div className="page-body">
                <Form
                    form={form}
                    mode='card'
                    layout='horizontal'
                    style={{'--prefix-width': '2.5em'}}
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <Form.Item name='type' label='类型' rules={[{ required: true }]}>
                        <Selector options={options} style={{'--padding': '3px 16px'}} />
                    </Form.Item>
                    <Form.Item name='name' label='名称' rules={[{ required: true }]}>
                        <Input placeholder='请输入名称' clearable={true} />
                    </Form.Item>
                    <Form.Item name='amount' label='数额' rules={[{ required: true }]}>
                        <Input type="number" placeholder='请输入数额' clearable={true} />
                    </Form.Item>
                    <Form.Item name='change_amount' label='调整'>
                        <Input type="number" placeholder='请输入调整数额' clearable={true} />
                    </Form.Item>
                    <Form.Item name='memo' label='备注'>
                        <Input placeholder='请输入调整备注' clearable={true} />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
};

export default PropertyEdit;