import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PullToRefresh, List, NavBar, Empty, Dialog, SwipeAction} from 'antd-mobile';
import {AddCircleOutline, CollectMoneyOutline} from "antd-mobile-icons";
import {createPropertyHistory, deleteProperty, getPropertyList} from "../../services/property";
import toast from "../../utils/toast";
import response from "../../utils/response";

import './list.css';

const PropertyList = () => {
    const navigate = useNavigate();

    const [netAssets, setNetAssets] = useState('0.00');
    const [propertyList, setPropertyList] = useState([]);
    const [propertyTotalAmount, setPropertyTotalAmount] = useState('0.00');
    const [liabilitiesList, setLiabilitiesList] = useState([]);
    const [liabilitiesTotalAmount, setLiabilitiesTotalAmount] = useState('0.00');

    function getData() {
        getPropertyList().then(res => {
            let {code, data} = res || {};

            if (response.isSuccess(code)) {
                setNetAssets(data?.net_assets);
                setPropertyList(data?.property_list);
                setPropertyTotalAmount(data?.property_amount);
                setLiabilitiesList(data?.liabilities_list);
                setLiabilitiesTotalAmount(data?.liabilities_amount);
            }
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const toAddPage = () => {
        navigate('/property/add');
    };

    const navBarLeft = (
        <div style={{ fontSize: 24 }}>
            <CollectMoneyOutline onClick={handleCreateHistory} />
        </div>
    );

    const navBarRight = (
        <div style={{ fontSize: 24 }}>
            <AddCircleOutline onClick={toAddPage} />
        </div>
    );

    function handleClick(id) {
        navigate('/property/logList/' + id);
    }

    function handleCreateHistory() {
        Dialog.confirm({
            content: '确定要生成记录吗？',
            onConfirm: async () => {
                createPropertyHistory().then(res => {
                    const {code} = res || {};

                    if (response.isSuccess(code)) {
                        toast.success('操作成功');
                    }
                });
            },
        });
    }

    function handleDelete(id) {
        Dialog.confirm({
            content: '确定要删除吗？',
            onConfirm: async () => {
                deleteProperty(id).then(res => {
                    const {code} = res || {};

                    if (response.isSuccess(code)) {
                        toast.success('删除成功');

                        setTimeout(() => getData(), 1000);
                    }
                });
            },
        });
    }

    return (
        <div className="page-container">
            <div className="page-top property-list-nav" onClick={()=>{}}>
                <NavBar
                    backArrow={false}
                    left={navBarLeft}
                    right={navBarRight}
                >
                    资产
                </NavBar>
            </div>
            <div className="page-body page-bg">
                <PullToRefresh
                    onRefresh={() => getData()}
                >
                    <div className="property-net-assets">
                        <p className="property-net-assets-amount">{netAssets}</p>
                        <p className="property-net-assets-name">净资产(元)</p>
                    </div>
                    <List header={<div className="property-list-amount" onClick={()=>{}}>资产<span>{propertyTotalAmount} 元</span></div>}>
                        {propertyList.map(item => (
                            <SwipeAction
                                key={item.id}
                                rightActions={[
                                    {
                                        key: 'delete',
                                        text: '删除',
                                        color: 'danger',
                                        onClick: () => handleDelete(item.id),
                                    },
                                ]}
                            >
                                <List.Item
                                    key={item.id}
                                    extra={item.amount + ' 元'}
                                    onClick={() => handleClick(item.id)}
                                >
                                    {item.name}
                                </List.Item>
                            </SwipeAction>
                        ))}
                        {propertyList.length === 0 ? (
                            <Empty
                                style={{ padding: '64px 0' }}
                                description='暂无数据'
                            />
                        ) : ''}
                    </List>
                    <List header={<div className="property-list-amount" onClick={()=>{}}>负债<span>{liabilitiesTotalAmount} 元</span></div>}>
                        {liabilitiesList.map(item => (
                            <SwipeAction
                                key={item.id}
                                rightActions={[
                                    {
                                        key: 'delete',
                                        text: '删除',
                                        color: 'danger',
                                        onClick: () => handleDelete(item.id),
                                    },
                                ]}
                            >
                                <List.Item
                                    key={item.id}
                                    extra={item.amount + ' 元'}
                                    onClick={() => handleClick(item.id)}
                                >
                                    {item.name}
                                </List.Item>
                            </SwipeAction>
                        ))}
                        {liabilitiesList.length === 0 ? (
                            <Empty
                                style={{ padding: '64px 0' }}
                                description='暂无数据'
                            />
                        ) : ''}
                    </List>
                </PullToRefresh>
            </div>
        </div>
    )
};

export default PropertyList;